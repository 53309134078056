import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            <p className="text-sm">
              Al Noori Store is your trusted source for high-quality cameras and accessories in Dubai.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="text-sm space-y-2">
              <li><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li><Link to="/products" className="hover:text-gray-300">Products</Link></li>
              <li><Link to="/about" className="hover:text-gray-300">About Us</Link></li>
              <li><Link to="/contact" className="hover:text-gray-300">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Customer Service</h3>
            <ul className="text-sm space-y-2">
              <li><Link to="/faq" className="hover:text-gray-300">FAQ</Link></li>
              <li><Link to="/shipping" className="hover:text-gray-300">Shipping & Returns</Link></li>
              <li><Link to="/warranty" className="hover:text-gray-300">Warranty</Link></li>
              <li><Link to="/support" className="hover:text-gray-300">Support</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <p className="text-sm">2 Maryam Mohammed Ahmed Al Otaiba Building - Deira - Al Baraha F.050 Office</p>
            <p className="text-sm">Phone: +971 4 385 9367</p>
            <p className="text-sm">Email: sales@alnooristore.com</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700">
          <div className="flex flex-wrap justify-between items-center">
            <p className="text-sm">
              &copy; {new Date().getFullYear()} Al Noori Store. All Rights Reserved.
            </p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <Link to="/terms" className="text-sm hover:text-gray-300">Terms of Service</Link>
              <Link to="/privacy" className="text-sm hover:text-gray-300">Privacy Policy</Link>
              <Link to="/legal" className="text-sm hover:text-gray-300">Legal Information</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;