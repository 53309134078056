import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AppProvider } from './context/AppContext'; // Import the AppProvider
import ScrollToTop from './components/ScrollToTop';
import CategoryProductsPage from './pages/CategoryProductsPage';
import CartPage from "./pages/CartPage";
import Header from "./components/Header";
import Legal from './pages/Legal';
import Footer from "./components/Footer";
import Contact from './pages/Contact';
import Home from "./pages/Home";
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductDetails from "./pages/ProductDetails";
import AboutUs from "./pages/AboutUs";
import CustomHeroSlider from './components/CustomHeroSlider';

const AppContent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="flex-col-layout">
      <ScrollToTop />
      <Header />
      {isHomePage && (
        <div className="w-full">
          <CustomHeroSlider />
        </div>
      )}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/category/:category" element={<CategoryProductsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <AppProvider>
      <Router>
        <AppContent />
      </Router>
    </AppProvider>
  );
};

export default App;