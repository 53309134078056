import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SaleCountdown from './SaleCountdown';
import { formatCurrency } from '../utils/formatCurrency';

const SaleProductCard = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [saleEnded, setSaleEnded] = useState(false);

  // Set the end date for the sale (e.g., 7 days from now)
  const endDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

  // Calculate discount percentage
  const discountPercentage = Math.round((1 - (product.price * 0.8) / product.price) * 100);

  return (
    <Link to={`/product/${product.id}`}>
      <motion.div 
        className="relative h-full flex flex-col cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex-grow relative">
          <img 
            src={isHovered && product.image[1] ? product.image[1] : product.image[0]} 
            alt={product.name} 
            className="w-full h-48 object-cover transition-all duration-300"
          />
          {!saleEnded && (
            <div className="absolute top-0 left-0 bg-red-500 text-white px-2 py-1 m-2 rounded">
              -{discountPercentage}%
            </div>
          )}
          <div className="p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{product.name}</h3>
            <div className="flex justify-between items-center">
              {!saleEnded ? (
                <>
                  <span className="text-lg font-bold text-red-600">{formatCurrency(product.price * 0.8)}</span>
                  <span className="text-sm text-gray-500 line-through">{formatCurrency(product.price)}</span>
                </>
              ) : (
                <span className="text-lg font-bold text-gray-800">{formatCurrency(product.price)}</span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-auto p-4 bg-gray-100">
          <SaleCountdown endDate={endDate} onEnd={() => setSaleEnded(true)} />
        </div>
      </motion.div>
    </Link>
  );
};

export default SaleProductCard;