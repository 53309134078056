import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">About Us - Al Noori Store</h1>
      <div className="space-y-4">
        <section>
          <p className="mb-4">
            Welcome to Al Noori Store, your trusted destination for premium cameras and accessories in Dubai. 
            We specialize in providing a curated selection of top-quality photography equipment, catering to 
            both professional photographers and passionate enthusiasts.
          </p>
          <p className="mb-4">
            At Al Noori Store, we understand the art of capturing moments and the need for reliable gear to 
            bring your creative vision to life. That's why we offer an extensive range of state-of-the-art 
            cameras, lenses, tripods, lighting equipment, and other essential accessories from globally 
            recognized brands.
          </p>
          <p className="mb-4">
            Our commitment to excellence extends beyond our products. We pride ourselves on delivering 
            exceptional customer service, ensuring that your shopping experience is smooth, enjoyable, and 
            tailored to your unique needs. Whether you're upgrading your setup or just starting your 
            photography journey, our team of experts is here to guide you every step of the way.
          </p>
          <p className="mb-4">
            Discover the perfect tools to elevate your craft at Al Noori Store—where quality meets trust.
          </p>
          <p className="font-semibold">
            Let us help you capture your world in stunning detail.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;