import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
  const imageUrl = Array.isArray(product.images) ? product.images[0] : 
                   (product.image || '/path/to/placeholder-image.jpg');

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <Link to={`/product/${product.id}`}>
        <img src={imageUrl} alt={product.name} className="w-full h-48 object-cover" />
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
          <p className="text-gray-600">{product.price} {product.currency}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;