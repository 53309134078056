import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProductCardWithHover from '../components/ProductCardWithHover';
import { ChevronRightIcon } from '@heroicons/react/solid';
import products from '../data/products';

const CategoryProductsPage = () => {
  const { category } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoryProducts = () => {
      setLoading(true);
      try {
        // Filter products based on the category
        const filteredProducts = products.filter(
          (product) => product.category.toLowerCase() === category.toLowerCase()
        );
        setCategoryProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching category products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [category]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-4">
        <Link to="/" className="text-gray-600 hover:text-gray-800">
          Home
        </Link>
        <ChevronRightIcon className="h-5 w-5 mx-2 text-gray-400" />
        <span className="text-gray-800 font-semibold">{category}</span>
      </div>

      <h1 className="text-3xl font-bold mb-6">{category} Products</h1>

      {categoryProducts.length === 0 ? (
        <p>No products found in this category.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {categoryProducts.map((product) => (
            <ProductCardWithHover key={product.id} product={product} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryProductsPage;